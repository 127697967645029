<template>
	<div class="display-flex flex-direction-column justify-content-space-between gap-150">
		<div class="performance-context-card__header body-md flex-1">{{ cardData.headline }}</div>
		<div class="display-flex justify-content-space-between align-items-center gap-50">
			<div class="body-xs text-color-dark-70">
				<div v-for="(footnote, idx) in cardData.footnotes" :key="idx">{{ footnote }}</div>
			</div>
			<div class="category-card-type-container">
				<span class="mr-25">
					<img
						:src="cardData.category.iconUrl"
						alt=""
						role="presentation"
						style="height: 16px; width: 16px"
					/>
				</span>
				<span class="body-sm text-color-dark-100">{{ cardData.category.name }}</span>
			</div>
		</div>
	</div>
</template>
<script setup lang="ts">
import { PerformanceContextCardCategoryEvent } from 'types/performance-context';

interface Props {
	cardData: PerformanceContextCardCategoryEvent;
}
defineProps<Props>();
</script>

<style lang="scss" scoped>
@use '../../../styles/constants/_colors.scss' as *;

.category-card-type-container {
	display: flex;
	align-items: center;
	padding: 2px 8px;
	border-radius: 3px;
	background-color: dark(5);
}
</style>
