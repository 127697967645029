// API Methods - https://developers.intercom.com/installing-intercom/docs/intercom-javascript
// API Attributes & Objects - https://developers.intercom.com/installing-intercom/docs/javascript-api-attributes-objects
import { app } from '@store/modules/app';
import { investmentEntity } from '@store/modules/investment-entity';
import { trackMixpanelEventWithBeacon } from '@utils/analytics';
import { user } from '@store/modules/user';

export const intercomEnabled = async (): Promise<boolean> => {
	const enabled = await app.isFeatureEnabled('intercom-chat-bot');
	const isWebview = app.isMobileWebview;
	const isSignup = app.isAuthenticated && !user.hasFirstInvestment;
	return enabled && !isWebview && !app.isAdvisor && !isSignup;
};

export const registerIntercom = async (): Promise<void> => {
	if (await intercomEnabled()) {
		window.Intercom('boot', {
			app_id: 'sj0c506e',
			hide_default_launcher: true,
			custom_launcher_selector: '#intercom-custom-launcher',
			alignment: 'right',
			vertical_padding: 64,
			horizontal_padding: 24,
			environment: import.meta.env.VITE_INTERCOM_ENV,
			isAuthenticated: app.isAuthenticated,
			user_id: user.user?.mixpanelId,
			fundrise_user_id: user.user?.id,
			zendeskId: user.user?.zendeskId,
			user_hash: user.user?.intercomIdentityVerification,
			// name: [user.user?.firstName, user.user?.lastName].join(' '),
			// email: user.user?.email,
			// phone: user.phoneData.currentPhoneNumber,
			investor: user.hasFirstInvestment,
			advisor: user.user?.isInvestmentAdvisor ?? false,
			accredited: investmentEntity.isVerifiedAccredited,
			pro: user.isSubscriptionActive,
			premium: user.isPremiumUser,
			subscriptionStatus: user.subscriptionStatus,
			created_at: user.user?.entityDetails?.firstInvestmentDate
		});

		window.Intercom('onShow', () => trackMixpanelEventWithBeacon('Intercom', { event: 'Show' }));
		window.Intercom('onHide', () => trackMixpanelEventWithBeacon('Intercom', { event: 'Hide' }));

		app.toggleIntercom(true);
	} else {
		await shutdownIntercom();
	}
};

export const shutdownIntercom = async (): Promise<void> => {
	window.Intercom('shutdown');
	app.toggleIntercom(false);
};

export const intercomShowNewMessage = (prepopulateMessage: string): void => {
	window.Intercom('showNewMessage', prepopulateMessage);
};
