<template>
	<StandardModal :data-context="viewName" @modal-close="close">
		<template #title>
			<div class="mr-150">
				<h3 class="heading-5">{{ cardData.summaryTitle }}</h3>
				<div v-if="!isPerformanceEvent" class="mt-25 body-md text-color-dark-80">{{ titleFootnotes }}</div>
			</div>
		</template>
		<template #content>
			<div
				v-if="isPerformanceEvent"
				class="display-flex align-items-center justify-content-space-between mb-100 p-75 bg-color-greige"
			>
				<div class="display-flex flex-direction-column body-md text-color-dark-80">
					<div v-for="footnote in cardData.footnotes" :key="footnote">{{ footnote }}</div>
				</div>
				<div :class="['metric-indicator-container', returnStyles.background]">
					<span v-if="returnStyles.indicator" :class="returnStyles.indicator"></span>
					<span :class="['body-md font-weight-bold', returnStyles.color]">{{ performanceMetric.value }}</span>
				</div>
			</div>
			<RichTextComponent :blocks="cardData.summaryContent" />
		</template>
		<template #button-section>
			<BaseButtonGroup>
				<template #secondary>
					<BaseButton :classes="`button ${ctaLink ? 'button-outline' : 'button-default'}`" @click="close"
						>Got it</BaseButton
					>
				</template>
				<template v-if="ctaLink" #primary>
					<BaseLink :link="ctaLink" classes="button button-default" @click="handleLinkClick" />
				</template>
			</BaseButtonGroup>
		</template>
	</StandardModal>
</template>
<script setup lang="ts">
import { computed, ref } from 'vue';
import {
	PerformanceContextCardData,
	PerformanceContextCardEventData,
	PerformanceContextCardPerformanceEvent,
	PerformanceContextMetric
} from 'types/performance-context';
import { getLinkFromRoute } from '@utils/request';
import { Link } from 'types/layout';
import RichTextComponent from '@components/sanity/rich-text-component.vue';
import StandardModal from '@components/modals/standard-modal.vue';
import { useAnalyticsProperties } from '@utils/composables/use-analytics-properties';
import { useRouter } from 'vue-router';

interface Props {
	card: PerformanceContextCardData;
}

interface Emits {
	(e: 'modal-close'): void;
}

const props = defineProps<Props>();

const emits = defineEmits<Emits>();

const router = useRouter();
const { setViewProperties, setCustomProperties } = useAnalyticsProperties();

const viewName = ref('Performance Context Summary View');
const customProperties = ref({ 'Event Name': props.card.eventName });
setViewProperties({ viewName });
setCustomProperties(customProperties);

const cardData = computed((): PerformanceContextCardEventData => props.card.cardData);

const isPerformanceEvent = computed((): boolean => cardData.value.type === 'PERFORMANCE');

const titleFootnotes = computed((): string => cardData.value.footnotes.join(' | '));

const performanceMetric = computed((): PerformanceContextMetric => {
	if (isPerformanceEvent.value) {
		const metric = (cardData.value as PerformanceContextCardPerformanceEvent).metric;

		return {
			value: metric.value,
			indicator: metric.indicator
		};
	}

	return { value: '', indicator: 'NEUTRAL' };
});

const returnStyles = computed((): { background: string; indicator: string; color: string } => {
	switch (performanceMetric.value.indicator) {
		case 'POSITIVE':
			return {
				background: 'positive',
				indicator: 'performance-portfolio__positive-returns',
				color: 'text-color-green'
			};

		case 'NEGATIVE':
			return {
				background: 'negative',
				indicator: 'performance-portfolio__negative-returns',
				color: 'text-color-red'
			};
		case 'NEUTRAL':
		default:
			return {
				background: 'neutral',
				indicator: '',
				color: 'text-color-dark-70'
			};
	}
});

const ctaLink = computed((): Link | undefined => {
	if (cardData.value.cta?.targetUrl) {
		return {
			...getLinkFromRoute(router, cardData.value.cta.targetUrl, 'Read more'),
			target: cardData.value.cta.newTab ? '_blank' : ''
		};
	}

	return undefined;
});

function close(): void {
	emits('modal-close');
}

function handleLinkClick(): void {
	if (!cardData.value.cta?.newTab) {
		close();
	}
}
</script>
<style lang="scss" scoped>
@use '../../../styles/components/performance-portfolio';
</style>
