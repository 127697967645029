import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { app } from './app';
import { getItemFromLocalStorage } from '@utils/web-storage';
import { isWebview } from '@utils/composables';
import store from '..';

@Module({
	dynamic: true,
	namespaced: true,
	name: 'googleSignUp',
	store
})
class GoogleSignUpModule extends VuexModule {
	private featureEnabled: boolean | null = null;
	private libraryLoaded = false;
	public darkMode = false;

	get leadCaptured(): boolean {
		return !!getItemFromLocalStorage('emailcapture');
	}

	get isLoading(): boolean {
		return !this.libraryLoaded || this.featureEnabled === null;
	}

	get isEnabled(): boolean {
		return !!this.featureEnabled && this.libraryLoaded && !this.leadCaptured && !isWebview.value;
	}

	@Action({ rawError: true })
	public async storeFeatureEnabled(): Promise<void> {
		const isUserInLDTestGroup = await app.isUserInLDTestGroup({
			featureFlag: '2024-08-15-sign-up-with-google-a-b-test',
			testGroup: 'test-with-google-signup'
		});
		this.UPDATE_FEATURE_ENABLED(isUserInLDTestGroup);
	}

	@Action({ rawError: true })
	public storeLibraryLoaded(): void {
		this.UPDATE_LIBRARY_LOADED(true);
	}

	@Action({ rawError: true })
	public setDarkMode(): void {
		this.UPDATE_DARK_MODE(true);
	}

	@Action({ rawError: true })
	public reset(): void {
		this.UPDATE_FEATURE_ENABLED(null);
		this.UPDATE_DARK_MODE(false);
	}

	@Mutation
	public UPDATE_FEATURE_ENABLED(enabled: boolean | null): void {
		this.featureEnabled = enabled;
	}

	@Mutation
	public UPDATE_LIBRARY_LOADED(loaded: boolean): void {
		this.libraryLoaded = loaded;
	}

	@Mutation
	public UPDATE_DARK_MODE(enabled: boolean): void {
		this.darkMode = enabled;
	}
}

export const googleSignUp = getModule(GoogleSignUpModule);
