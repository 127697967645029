import { createRouter, createWebHistory, FundriseRouteConfig } from 'vue-router';
import accountRoutes from './account/account';
import accreditationRoutes from './accreditation';
import addTrackers from '@utils/trackers';
import advisorRoutes from './advisor/advisor';
import { app } from '@store/modules/app';
import { appBasePath } from '@constants';
import appRedirectRoutes from './app-redirect';
import checkoutRoutes from './checkout/checkout';
import customerBetaRoutes from './customer-beta';
import customPlanRoutes from './account/custom-plans';
import documentRedirectRoutes from './document-redirect';
import educationRoutes from './education';
import emailUnsubscribeRoutes from './email';
import fundLandingPageRoutes from './fund-landing-pages';
import { getOS } from '@utils/client';
import growthCalculatorRoutes from './growth-calculator';
import { handleAuthentication } from './auth-guard';
import helpCenterRoutes from './help-center';
import homeRedirects from './redirects/home-redirects';
import { initializeUserway } from '@utils/accessibility';
import { investmentEntity } from '@store/modules/investment-entity';
import investmentGoalRoutes from './investment-goals';
import investmentPlanningRoutes from './investment-planning';
import investmentsRedirects from './redirects/investments-redirects';
import iraAddFundsRoutes from './ira/ira-add-funds';
import iraCompleteOrderRoutes from './ira/ira-complete-order';
import iraCompleteRolloverRoutes from './ira/ira-complete-rollover';
import iraGeneralRoutes from './ira/ira-general';
import iraReacknowledgeRolloverRoutes from './ira/ira-reacknowledge-rollover';
import iraSignupRoutes from './ira/ira-signup';
import iraVerifyRoutes from './ira/ira-verify';
import legalRoutes from './legal';
import loginRoutes from './login/login';
import marketingRedirectRoutes from './marketing-redirects';
import microFeedbackRoutes from './micro-feedback';
import mobileRedirectRoutes from './mobile-redirect';
import modularLandingPageRoutes from './modular-landing-pages';
import oauthAuthorizationRoutes from './oauth-authorization/oauth-authorization';
import offeringsRoutes from './offerings';
import { pageViewed } from '@utils/page-viewed';
import prismicPreviewRoutes from './prismic';
import privateDocumentRedirectRoutes from './private-document-redirect';
import { proRouteGuard } from './utils/pro-route-guard';
import questionInfoFrameworkRoutes from './question-info-framework';
import redemptionRequestRoutes from './redemption-request';
import redirectRoutes from './redirects';
import referralRoutes from './referral';
import resetPasswordRoutes from './forgot-password/forgot-password';
import settingsRoutes from './account/settings';
import shareholderVoteRoutes from './shareholder-vote';
import signupRoutes from './signup';
import { trackMixpanelEvent } from '@utils/analytics';

export const routes: readonly FundriseRouteConfig[] = [
	{
		path: `/about`,
		name: 'about',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		component: () => import('@views/marketing-site/about-view.vue')
	},
	{
		path: `/download`,
		name: 'download-app',
		redirect: () => {
			const EVENT_NAME = 'App Download Redirect';
			const PROPERTY_REDIRECT_NAME = 'Redirect Destination';

			const deviceOS = getOS();

			let url: string;
			if (deviceOS === 'Android') {
				url = `${import.meta.env.VITE_AVATAR_URL}/play-store-redirect`;
			} else {
				url = `${import.meta.env.VITE_AVATAR_URL}/app-store-redirect`;
			}

			trackMixpanelEvent(EVENT_NAME, {
				[PROPERTY_REDIRECT_NAME]: url
			});
			window.location.href = url;

			return { name: 'home' };
		}
	},
	{
		path: `/press`,
		name: 'press',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		component: () => import('@views/marketing-site/press-and-media.vue')
	},
	{
		path: `/`,
		name: 'home',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		component: () => import('@views/marketing-site/home-view.vue'),
		beforeEnter: async (to, from, next) => {
			if (app.isAuthenticated) {
				// If user is unverified & goes home, log them out.
				if (!app.isVerified) {
					await app.revokeUserToken();
					next();
				} else if (app.isAdvisor) {
					next({ name: 'advisor-dashboard' });
				} else {
					next({ name: 'account-overview' });
				}
			} else {
				next();
			}
		}
	},
	{
		path: `/how-it-works`,
		name: 'how-it-works',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		component: () => import('@views/marketing-site/how-it-works.vue')
	},
	{
		path: `/investments`,
		alias: '/investments-explorer',
		name: 'investments',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		redirect: { name: 'home' }
	},
	{
		path: '/private-credit',
		name: 'private-credit',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		component: () => import('@views/marketing-site/private-credit.vue')
	},

	{
		path: '/pro',
		name: 'pro',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		component: () => import('@views/marketing-site/fundrise-pro.vue')
	},
	{
		path: `/venture`,
		name: 'venture-capital',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		component: () => import('@views/marketing-site/venture-capital.vue')
	},
	{
		path: `/investments/advanced-plans/compare`,
		name: 'compare-advanced-plans',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		redirect: { name: 'compare-core-plans' }
	},
	{
		path: `/investments/core-plans/:investmentStrategySlug/view-plan-variants`,
		name: 'start-investing-core-plans-variants-redirect',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		redirect: { name: 'compare-core-plans' }
	},
	{
		path: `/investments/advanced-plans/:planSlug`,
		props: true,
		name: 'plan-detail-pages',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		component: () => import('@views/marketing-site/advanced-plan.vue')
	},
	{
		path: `/jobs`,
		name: 'careers',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		component: () => import('@views/marketing-site/careers-view.vue')
	},
	{
		path: `/assets`,
		alias: '/assets/**',
		name: 'assets',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		component: () => import('@views/marketing-site/assets-view.vue')
	},
	{
		path: `/referral-program/landing`,
		name: 'investor-referral-landing-redirect',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		redirect: { name: 'referral-landing' }
	},
	{
		path: `/invitation-program/landing`,
		name: 'referral-landing',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		beforeEnter: async (to, from, next) => {
			if (investmentEntity.userReferralEligibilityStatus === 'INELIGIBLE') {
				next({ name: 'account-overview' });
			} else {
				next();
			}
		},
		component: () => import('@views/referral/referral-landing.vue')
	},
	{
		path: `/404`,
		name: '404',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		component: () => import('@views/error/not-found-404.vue')
	},
	{
		path: `/forbidden`,
		name: 'forbidden',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		component: () => import('@views/error/forbidden-view.vue')
	},
	{
		path: `/guides/real-estate-investing-guide`,
		name: 'rei-guide',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		component: () => import('@views/marketing-site/rei-guide.vue')
	},
	{
		path: `/why-fundrise`,
		name: 'why-fundrise',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		component: () => import('@views/marketing-site/why-fundrise.vue')
	},
	{
		path: `/why-private-real-estate`,
		name: 'why-private-real-estate',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		redirect: { name: 'real-estate-strategies' }
	},
	{
		path: `/real-estate-assets/:reitElementId/view`,
		name: 'real-estate-assets',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED', suppressDefaultMxpViewEvent: true },
		component: () => import('@views/marketing-site/asset-details-wrapper.vue')
	},
	{
		path: `/company-assets/:companyId/view`,
		name: 'company-assets',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED', suppressDefaultMxpViewEvent: true },
		component: () => import('@views/marketing-site/asset-details-wrapper.vue')
	},
	{
		path: `/bond-assets/:bondId/view`,
		name: 'bond-assets',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED', suppressDefaultMxpViewEvent: true },
		component: () => import('@views/marketing-site/asset-details-wrapper.vue')
	},
	{
		path: `/venture-fund-assets/:ventureFundId/view`,
		name: 'venture-fund-assets',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED', suppressDefaultMxpViewEvent: true },
		component: () => import('@views/marketing-site/asset-details-wrapper.vue')
	},
	{
		path: `/real-estate-strategies`,
		name: 'real-estate-strategies',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		component: () => import('@views/marketing-site/real-estate-strategies.vue')
	},
	{
		path: `/strategy`,
		name: 'strategy',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		redirect: { name: 'real-estate-strategies' }
	},
	{
		path: `/ereits`,
		name: 'ereits',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		component: () => import('@views/marketing-site/ereits-view.vue')
	},
	{
		path: `/client-returns`,
		name: 'client-returns',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		component: () => import('@views/marketing-site/client-returns.vue')
	},
	{
		path: `/the-distance`,
		name: 'the-distance',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		component: () => import('@views/newsletter/newsletter-landing-page.vue')
	},
	{
		path: `/connect-api`,
		name: 'connect-api',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		component: () => import('@views/marketing-site/connect-api.vue')
	},
	{
		path: `/connect-api/contact`,
		name: 'connect-api-contact',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		component: () => import('@views/marketing-site/connect-api-contact.vue')
	},
	{
		path: `/webview/welcome-screen`,
		name: 'webview-welcome-screen',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		component: () => import('@views/webview/webview-welcome-screen.vue')
	},
	{
		path: `/webview/pro`,
		name: 'webview-pro',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		component: () => import('@views/webview/pro-webview.vue')
	},
	{
		path: `/webview/plan-details/:slug`,
		name: 'webview-plan-details',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		component: () => import('@views/webview/plan-details.vue')
	},

	{
		path: `/webview/legal-disclosure`,
		name: 'webview-legal-disclosure',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		component: () => import('@views/webview/legal-disclosure.vue')
	},
	{
		path: `/webview/track-record-assumptions`,
		name: 'webview-track-record-assumptions',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		component: () => import('@views/webview/track-record-assumptions.vue')
	},
	{
		path: `/story/complete`,
		name: 'story-complete',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		component: () => import('@views/stories/story-complete.vue')
	},
	{
		path: `/story/:id`,
		name: 'story-player',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		component: () => import('@views/stories/story-player.vue')
	},
	{
		path: `/user-research/confirm`,
		name: 'user-research-confirm',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		component: () => import('@views/user-research/user-research-confirm.vue')
	},
	{
		path: '/investor-update/:investorUpdateId/view',
		name: 'investor-update',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		beforeEnter: async (to, from, next) => {
			const isId = /^\d+$/.test(to.params.investorUpdateId as string);
			if (isId) {
				next();
			} else {
				next({ name: '404' });
			}
		},
		component: () => import('@views/investor-update/investor-update-detail.vue')
	},
	{
		path: '/property-update/:investorUpdateId/view',
		name: 'property-update',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		beforeEnter: async (to, from, next) => {
			const isId = /^\d+$/.test(to.params.investorUpdateId as string);
			if (isId) {
				next();
			} else {
				next({ name: '404' });
			}
		},
		component: () => import('@views/investor-update/investor-update-detail.vue')
	},
	{
		path: `/get-iphone-app`,
		name: 'get-iphone-app',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		component: () => import('@views/mobile/get-iphone-app.vue')
	},
	{
		path: `/get-android-app`,
		name: 'get-android-app',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		component: () => import('@views/mobile/get-android-app.vue')
	},
	{
		path: `/contact`,
		name: 'contact',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		component: () => import('@views/contact/contact-view.vue')
	},
	{
		path: '/data-visualizations/:dataVisualization',
		name: 'data-visualization',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		component: () => import('@views/data-visualization/data-visualization.vue')
	},
	{
		path: `/advanced-reporting`,
		name: 'advanced-reporting',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		beforeEnter: proRouteGuard('advancedReporting'),
		component: () => import('@views/basis-advanced-reporting/basis-advanced-reporting.vue')
	},
	{
		path: '/maintenance',
		name: 'maintenance',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		beforeEnter: async (to, from, next) => {
			const isMaintenanceWindow = await app.isFeatureEnabled('app-down-maintenance-window');
			if (!isMaintenanceWindow) {
				next({ name: 'home' });
			} else {
				next();
			}
		},
		component: () => import('@views/maintenance-page.vue')
	},
	{
		path: '/ria-plan/:riaPlanId/strategy',
		name: 'ria-plan-strategy',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		component: () => import('@views/ria-plan/strategy-details.vue')
	},
	{
		path: '/investor-asset-class/:assetClass',
		name: 'asset-class-cross-sell',
		meta: {
			minTokenScopeRequired: 'FULL_ACCESS'
		},
		component: () => import('@views/account/account-cross-sell.vue')
	},
	...accountRoutes,
	...advisorRoutes,
	...appRedirectRoutes,
	...checkoutRoutes,
	...customPlanRoutes,
	...customerBetaRoutes,
	...documentRedirectRoutes,
	...educationRoutes,
	...emailUnsubscribeRoutes,
	...fundLandingPageRoutes,
	...growthCalculatorRoutes,
	...helpCenterRoutes,
	...investmentGoalRoutes,
	...investmentPlanningRoutes,
	...iraGeneralRoutes,
	...iraSignupRoutes,
	...legalRoutes,
	...loginRoutes,
	...marketingRedirectRoutes,
	...microFeedbackRoutes,
	...mobileRedirectRoutes,
	...modularLandingPageRoutes,
	...oauthAuthorizationRoutes,
	...offeringsRoutes,
	...prismicPreviewRoutes,
	...privateDocumentRedirectRoutes,
	...questionInfoFrameworkRoutes,
	...redemptionRequestRoutes,
	...redirectRoutes,
	...referralRoutes,
	...resetPasswordRoutes,
	...settingsRoutes,
	...shareholderVoteRoutes,
	...signupRoutes,
	accreditationRoutes,
	iraAddFundsRoutes,
	iraCompleteOrderRoutes,
	iraCompleteRolloverRoutes,
	iraReacknowledgeRolloverRoutes,
	iraVerifyRoutes
];

const router = createRouter({
	history: createWebHistory(`${appBasePath}`),
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition;
		} else {
			if (to.hash) {
				return {
					el: to.hash,
					behavior: 'smooth',
					top: 70
				};
			}

			return { left: 0, top: 0 };
		}
	},
	routes
});

router.beforeEach(async (to, from) => {
	const redirectArray = [homeRedirects, investmentsRedirects];
	const foundRedirect = redirectArray.find((entry) => to.path.match(entry.regExPaths) !== null);

	if (foundRedirect) {
		return { name: foundRedirect.routeName, replace: true };
	} else if (!to.matched.length) {
		return '/404';
	} else {
		return handleAuthentication(to, from);
	}
});

router.isReady().then(async () => {
	if (!app.isMobileWebview) {
		await initializeUserway();

		if (import.meta.env.VITE_ENABLE_CONVERSION_PIXELS === 'true') {
			addTrackers();
		}
	}
});

router.beforeResolve((to) => {
	app.getCurrentBanner(to.path);
});

router.afterEach(async (to) => {
	pageViewed(to.meta?.suppressDefaultMxpViewEvent ?? false);
});

export default router;
