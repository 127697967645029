import {
	CustomAllocationOfferingResponse,
	CustomAllocationPlanRequest,
	CustomAllocationPlanResponse,
	CustomAllocationType
} from 'types/custom-plans';
import { investmentEntityRequest } from '@utils/request';

export const getCustomAllocationPlan = async (): Promise<CustomAllocationPlanResponse> => {
	const response = await investmentEntityRequest.get('/custom-investment-plan');

	return response.data;
};

export const getCustomAllocationsOfferingsListForEntity = async (): Promise<
	Array<CustomAllocationOfferingResponse>
> => {
	const response = await investmentEntityRequest.get('/custom-allocation/offerings');

	return response.data.reits;
};

export const saveCustomAllocationPlan = async (
	payload: CustomAllocationPlanRequest
): Promise<CustomAllocationPlanResponse> => {
	const response = await investmentEntityRequest.post('/custom-investment-plan', payload);

	return response.data;
};

export const saveAllocationType = async (
	allocationType: CustomAllocationType
): Promise<CustomAllocationPlanResponse> => {
	const response = await investmentEntityRequest.post('/custom-investment-plan/allocation-type', { allocationType });

	return response.data;
};
