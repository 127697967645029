import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import {
	getIpoRedemptionRequestMetadata,
	getRedemptionPreview,
	getReitRedemptionRequestMetadata,
	submitRequest
} from '@api/redemption-request';
import {
	RedeemableReitItem,
	RedemptionPreference,
	RedemptionRequestMetadata,
	RedemptionRequestPreviewData,
	RedemptionRequestSurvey,
	RedemptionRequestType,
	RedemptionSubmission,
	ReitRedemptionRequest
} from 'types/redemption-request';
import { app } from './app';
import { investmentEntity } from './investment-entity';
import { investmentGoals } from './investment-goals';
import store from '..';
import { user } from './user';

@Module({
	dynamic: true,
	namespaced: true,
	name: 'redemptionRequest',
	store
})
class RedemptionRequestModule extends VuexModule {
	autoInvestor = false;
	expectedQuarterlyProcessingDate = '';
	fullRedemptionExcludesBlockedFunds = false;
	futureQuarterlyProcessingDates: Array<string> = [];
	hasCurrentInvestmentGoal = false;
	hasPendingDrip = false;
	hasPendingRecurringInvestments = false;
	hasPendingRedemptions = false;
	hasPendingReitInvestments = false;
	hasReitsEligibleForRedemption = false;
	hasUnredeemedIpoShareholdings = false;
	nextRequestDeadline = '';
	reInvestor = false;
	redeemableReits: Array<RedeemableReitItem> = [];
	redemptionType: RedemptionRequestType = 'REIT';
	redemptionRequestInterstitialTestFlag: string | null = null;
	redemptionRequestInterstitialVariant: string | null = 'control';
	redemptionRequests: Array<ReitRedemptionRequest> = [];
	redemptionPreference: RedemptionPreference | null = null;
	redemptionPreview: RedemptionRequestPreviewData | null = null;
	redemptionRequestGroupId = '';
	showProceeds = false;
	submitted = false;
	survey: RedemptionRequestSurvey = {
		redemptionReason: null,
		marketReason: null,
		dissatisfactionSubtype: null,
		personalReason: null,
		customMarketReason: '',
		customDissatisfactionReason: '',
		customPersonalReason: '',
		additionalComments: ''
	};

	get hasRedeemableReits(): boolean {
		return this.redeemableReits.length > 0;
	}

	get hasRequests(): boolean {
		return this.redemptionRequests.length > 0;
	}

	get showRedemptionRequestInterstitial(): boolean {
		return !!this.redemptionRequestInterstitialVariant;
	}

	get completedSurvey(): boolean {
		return this.survey.redemptionReason !== null;
	}

	get skipPreferencePage(): boolean {
		return this.hasPendingRedemptions;
	}

	@Action({ rawError: true })
	public async fetchRedemptionRequestInterstitialVariant(): Promise<void> {
		if (this.redemptionRequestInterstitialTestFlag && this.redemptionRequestInterstitialVariant === null) {
			const variant = await app.getLdAbTestGroup(this.redemptionRequestInterstitialTestFlag);
			this.UPDATE_REDEMPTION_REQUEST_INTERSTITIAL_VARIANT(variant);
		}
	}

	@Action({ rawError: true })
	public async fetchAndStoreMetadata(): Promise<void> {
		const redemptionRequestData =
			this.redemptionType === 'REIT'
				? await getReitRedemptionRequestMetadata()
				: await getIpoRedemptionRequestMetadata();
		this.storeMetadata(redemptionRequestData);
	}

	@Action({ rawError: true })
	public async storeMetadata(redemptionRequestData: RedemptionRequestMetadata): Promise<void> {
		this.UPDATE_AUTO_INVESTOR(redemptionRequestData.autoInvestor);
		this.UPDATE_EXPECTED_QUARTERLY_PROCESSING_DATE(redemptionRequestData.expectedQuarterlyProcessingDate);
		this.UPDATE_FULL_REDEMPTION_EXCLUDES_BLOCKED_FUNDS(redemptionRequestData.fullRedemptionExcludesBlockedFunds);
		this.UPDATE_FUTURE_QUARTERLY_PROCESSING_DATES(redemptionRequestData.futureQuarterlyProcessingDates);
		this.UPDATE_HAS_CURRENT_INVESTMENT_GOAL(redemptionRequestData.hasCurrentInvestmentGoal);
		this.UPDATE_HAS_PENDING_DRIP(redemptionRequestData.hasPendingDrip);
		this.UPDATE_HAS_PENDING_RECURRING_INVESTMENTS(redemptionRequestData.hasPendingRecurringInvestments);
		this.UPDATE_HAS_PENDING_REDEMPTIONS(redemptionRequestData.hasPendingRedemptions);
		this.UPDATE_HAS_PENDING_REIT_INVESTMENTS(redemptionRequestData.hasPendingReitInvestments);
		this.UPDATE_HAS_REITS_ELIGIBLE_FOR_REDEMPTION(redemptionRequestData.hasReitsEligibleForRedemption);
		this.UPDATE_HAS_UNREDEEMED_IPO_SHAREHOLDINGS(redemptionRequestData.hasUnredeemedIpoShareholdings);
		this.UPDATE_NEXT_REQUEST_DEADLINE(redemptionRequestData.nextRequestDeadline);
		this.UPDATE_REINVESTOR(redemptionRequestData.reInvestor);
		this.UPDATE_REDEEMABLE_REITS(redemptionRequestData.redeemableReits);
		this.UPDATE_REDEMPTION_REQUESTS([]);
		this.UPDATE_REDEMPTION_PREFERENCE(null);
		this.UPDATE_REDEMPTION_PREVIEW(null);
		this.UPDATE_REDEMPTION_REQUEST_GROUP_ID('');
		this.UPDATE_SUBMITTED(false);
		this.UPDATE_SURVEY({
			redemptionReason: null,
			marketReason: null,
			dissatisfactionSubtype: null,
			personalReason: null,
			customMarketReason: '',
			customDissatisfactionReason: '',
			customPersonalReason: '',
			additionalComments: ''
		});
	}

	@Action({ rawError: true })
	public async storeRedemptionPreview(): Promise<void> {
		this.UPDATE_REDEMPTION_PREVIEW(null);
		const redemptionPreview = await getRedemptionPreview(this.redemptionRequests);
		this.UPDATE_REDEMPTION_PREVIEW(redemptionPreview);
	}

	@Action({ rawError: true })
	public async submitPreference(redemptionPreference: RedemptionPreference): Promise<string> {
		this.UPDATE_REDEMPTION_PREFERENCE(redemptionPreference);

		if (this.redemptionPreference === 'FULL') {
			const allReitsForRedemption = this.redeemableReits.map((reitRequest) => {
				return {
					reitId: reitRequest.reitId,
					sharesRequested: reitRequest.sharesOutstanding
				};
			});
			this.UPDATE_REDEMPTION_REQUESTS(allReitsForRedemption);
			return `redemption-request-review-${this.redemptionType}`;
		} else {
			return `redemption-request-form-${this.redemptionType}`;
		}
	}

	@Action({ rawError: true })
	public async submitRequest(): Promise<string> {
		const redemptionSubmission: RedemptionSubmission = {
			allAcknowledgementsAccepted: true,
			investmentEntityId: investmentEntity.investmentEntityId,
			requests: this.redemptionRequests,
			survey: this.survey
		};
		const redemptionRequestGroupId = await submitRequest(redemptionSubmission);

		await user.getUser();
		investmentGoals.reset();

		this.UPDATE_REDEMPTION_REQUEST_GROUP_ID(redemptionRequestGroupId);
		this.UPDATE_SUBMITTED(true);
		return `redemption-request-success-${this.redemptionType}`;
	}

	@Action({ rawError: true })
	public async storeSurveyResponse(survey: RedemptionRequestSurvey): Promise<string> {
		this.UPDATE_SURVEY(survey);
		return `redemption-request-agreements-${this.redemptionType}`;
	}

	@Action({ rawError: true })
	public async submitRequestForm(redemptionRequestForm: Array<ReitRedemptionRequest>): Promise<string> {
		this.UPDATE_REDEMPTION_REQUESTS(redemptionRequestForm);
		return `redemption-request-review-${this.redemptionType}`;
	}

	@Action({ rawError: true })
	public reset(): void {
		this.UPDATE_AUTO_INVESTOR(false);
		this.UPDATE_EXPECTED_QUARTERLY_PROCESSING_DATE('');
		this.UPDATE_FULL_REDEMPTION_EXCLUDES_BLOCKED_FUNDS(false);
		this.UPDATE_FUTURE_QUARTERLY_PROCESSING_DATES([]);
		this.UPDATE_HAS_CURRENT_INVESTMENT_GOAL(false);
		this.UPDATE_HAS_PENDING_DRIP(false);
		this.UPDATE_HAS_PENDING_RECURRING_INVESTMENTS(false);
		this.UPDATE_HAS_PENDING_REDEMPTIONS(false);
		this.UPDATE_HAS_PENDING_REIT_INVESTMENTS(false);
		this.UPDATE_HAS_REITS_ELIGIBLE_FOR_REDEMPTION(false);
		this.UPDATE_HAS_UNREDEEMED_IPO_SHAREHOLDINGS(false);
		this.UPDATE_NEXT_REQUEST_DEADLINE('');
		this.UPDATE_REINVESTOR(false);
		this.UPDATE_REDEEMABLE_REITS([]);
		this.UPDATE_REDEMPTION_REQUESTS([]);
		this.UPDATE_REDEMPTION_PREFERENCE(null);
		this.UPDATE_REDEMPTION_PREVIEW(null);
		this.UPDATE_REDEMPTION_REQUEST_GROUP_ID('');
		this.UPDATE_SUBMITTED(false);
		this.UPDATE_SURVEY({
			redemptionReason: null,
			marketReason: null,
			dissatisfactionSubtype: null,
			personalReason: null,
			customMarketReason: '',
			customDissatisfactionReason: '',
			customPersonalReason: '',
			additionalComments: ''
		});
	}

	@Mutation
	public SET_REDEMPTION_TYPE(redemptionType: RedemptionRequestType): void {
		this.redemptionType = redemptionType;
	}

	@Mutation
	public UPDATE_REDEMPTION_REQUEST_GROUP_ID(redemptionRequestGroupId: string): void {
		this.redemptionRequestGroupId = redemptionRequestGroupId;
	}

	@Mutation
	public UPDATE_SHOW_PROCEEDS(showProceeds: boolean): void {
		this.showProceeds = showProceeds;
	}

	@Mutation
	public UPDATE_REDEMPTION_PREFERENCE(redemptionPreference: RedemptionPreference | null): void {
		this.redemptionPreference = redemptionPreference;
	}

	@Mutation
	public UPDATE_AUTO_INVESTOR(autoInvestor: boolean): void {
		this.autoInvestor = autoInvestor;
	}

	@Mutation
	public UPDATE_EXPECTED_QUARTERLY_PROCESSING_DATE(expectedQuarterlyProcessingDate: string): void {
		this.expectedQuarterlyProcessingDate = expectedQuarterlyProcessingDate;
	}

	@Mutation
	public UPDATE_FULL_REDEMPTION_EXCLUDES_BLOCKED_FUNDS(fullRedemptionExcludesBlockedFunds: boolean): void {
		this.fullRedemptionExcludesBlockedFunds = fullRedemptionExcludesBlockedFunds;
	}

	@Mutation
	public UPDATE_FUTURE_QUARTERLY_PROCESSING_DATES(futureQuarterlyProcessingDates: Array<string>) {
		this.futureQuarterlyProcessingDates = futureQuarterlyProcessingDates;
	}

	@Mutation
	public UPDATE_HAS_CURRENT_INVESTMENT_GOAL(hasCurrentInvestmentGoal: boolean): void {
		this.hasCurrentInvestmentGoal = hasCurrentInvestmentGoal;
	}

	@Mutation
	public UPDATE_HAS_PENDING_DRIP(hasPendingDrip: boolean): void {
		this.hasPendingDrip = hasPendingDrip;
	}

	@Mutation
	public UPDATE_HAS_PENDING_RECURRING_INVESTMENTS(hasPendingRecurringInvestments: boolean): void {
		this.hasPendingRecurringInvestments = hasPendingRecurringInvestments;
	}

	@Mutation
	public UPDATE_HAS_PENDING_REDEMPTIONS(hasPendingRedemptions: boolean): void {
		this.hasPendingRedemptions = hasPendingRedemptions;
	}

	@Mutation
	public UPDATE_HAS_PENDING_REIT_INVESTMENTS(hasPendingReitInvestments: boolean): void {
		this.hasPendingReitInvestments = hasPendingReitInvestments;
	}

	@Mutation
	public UPDATE_HAS_REITS_ELIGIBLE_FOR_REDEMPTION(hasReitsEligibleForRedemption: boolean): void {
		this.hasReitsEligibleForRedemption = hasReitsEligibleForRedemption;
	}

	@Mutation
	public UPDATE_HAS_UNREDEEMED_IPO_SHAREHOLDINGS(hasUnredeemedIpoShareholdings: boolean): void {
		this.hasUnredeemedIpoShareholdings = hasUnredeemedIpoShareholdings;
	}

	@Mutation
	public UPDATE_NEXT_REQUEST_DEADLINE(nextRequestDeadline: string): void {
		this.nextRequestDeadline = nextRequestDeadline;
	}

	@Mutation
	public UPDATE_REINVESTOR(reInvestor: boolean): void {
		this.reInvestor = reInvestor;
	}

	@Mutation
	public UPDATE_REDEEMABLE_REITS(redeemableReits: Array<RedeemableReitItem>): void {
		this.redeemableReits = redeemableReits;
	}

	@Mutation
	public UPDATE_REDEMPTION_REQUESTS(redemptionRequests: Array<ReitRedemptionRequest>): void {
		this.redemptionRequests = redemptionRequests;
	}

	@Mutation
	public UPDATE_SURVEY(survey: RedemptionRequestSurvey): void {
		this.survey = survey;
	}

	@Mutation
	public UPDATE_REDEMPTION_PREVIEW(preview: RedemptionRequestPreviewData | null): void {
		this.redemptionPreview = preview;
	}

	@Mutation
	public UPDATE_SUBMITTED(submitted: boolean): void {
		this.submitted = submitted;
	}

	@Mutation
	public UPDATE_REDEMPTION_REQUEST_INTERSTITIAL_VARIANT(variant: string): void {
		this.redemptionRequestInterstitialVariant = variant;
	}
}

export const redemptionRequest = getModule(RedemptionRequestModule);
