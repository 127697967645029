<template>
	<StandardModal data-context="MicroFeedbackPerformanceContextFormModal" @modal-close="close">
		<template #title>
			<h3 class="heading-5">Share your feedback</h3>
		</template>
		<template #content>
			<div class="display-flex flex-direction-column">
				<p class="mt-0"
					>We are testing a collection of changes to the top portion of your dashboard – including new
					“events” represented as annotations on the account value chart and as cards underneath. Once you’ve
					had a chance to explore the feature, we’d love to hear your thoughts.</p
				>

				<hr class="divider mx-0 my-150" />

				<InputField :field="v$.PERFORMANCE_CONTEXT_RATE_NEW_FEATURE.satisfactionResponse">
					<template #label
						>How would you rate the updated experience of your dashboard and the new feature
						overall?</template
					>
					<template #input>
						<MicroFeedbackRadios
							classes="mt-100"
							:selected-response="state.PERFORMANCE_CONTEXT_RATE_NEW_FEATURE.satisfactionResponse"
							:reaction-labels="['Poor', 'Somewhat poor', 'Neutral', 'Somewhat excellent', 'Excellent']"
							@reaction-selected="reactionSelected('PERFORMANCE_CONTEXT_RATE_NEW_FEATURE', $event)"
						/>
					</template>
				</InputField>

				<hr class="divider mx-0 my-150" />

				<InputField :field="v$.PERFORMANCE_CONTEXT_EASE_OF_USE.satisfactionResponse">
					<template #label>Do you find this feature easy or difficult to use?</template>

					<template #input>
						<MicroFeedbackRadios
							classes="mt-100"
							:selected-response="state.PERFORMANCE_CONTEXT_EASE_OF_USE.satisfactionResponse"
							:reaction-labels="[
								'Very difficult',
								'Somewhat difficult',
								'Neutral',
								'Somewhat easy',
								'Very easy'
							]"
							@reaction-selected="reactionSelected('PERFORMANCE_CONTEXT_EASE_OF_USE', $event)"
						/>
					</template>
				</InputField>

				<hr class="divider mx-0 my-150" />

				<InputField :field="v$.PERFORMANCE_CONTEXT_IMPACT_ON_RETURNS.satisfactionResponse">
					<template #label
						>After using this feature, I have a better understanding of what is impacting my account’s
						recent performance.</template
					>

					<template #input>
						<MicroFeedbackRadios
							classes="mt-100"
							:selected-response="state.PERFORMANCE_CONTEXT_IMPACT_ON_RETURNS.satisfactionResponse"
							:reaction-labels="[
								'Strongly disagree',
								'Somewhat disagree',
								'Neutral',
								'Somewhat agree',
								'Strongly agree'
							]"
							@reaction-selected="reactionSelected('PERFORMANCE_CONTEXT_IMPACT_ON_RETURNS', $event)"
						/>
					</template>
				</InputField>

				<InputField class="mt-100" :field="v$.PERFORMANCE_CONTEXT_IMPACT_ON_RETURNS.additionalFeedback">
					<template #label>Tell us more about your rating.</template>

					<template #input>
						<textarea
							v-model.trim="v$['PERFORMANCE_CONTEXT_IMPACT_ON_RETURNS'].$model.additionalFeedback"
							class="input mt-100"
							placeholder="Start typing (optional)"
							maxlength="1000"
							cols="100"
							:rows="1"
						>
						</textarea>
					</template>
				</InputField>

				<hr class="divider mx-0 my-150" />

				<InputField :field="v$.PERFORMANCE_CONTEXT_PERFORMANCE_TRANSPARENCY.satisfactionResponse">
					<template #label
						>This feature provides improved clarity and transparency around recent portfolio
						performance.</template
					>

					<template #input>
						<MicroFeedbackRadios
							classes="mt-100"
							:selected-response="state.PERFORMANCE_CONTEXT_PERFORMANCE_TRANSPARENCY.satisfactionResponse"
							:reaction-labels="[
								'Strongly disagree',
								'Somewhat disagree',
								'Neutral',
								'Somewhat agree',
								'Strongly agree'
							]"
							@reaction-selected="
								reactionSelected('PERFORMANCE_CONTEXT_PERFORMANCE_TRANSPARENCY', $event)
							"
						/>
					</template>
				</InputField>

				<InputField class="mt-100" :field="v$.PERFORMANCE_CONTEXT_PERFORMANCE_TRANSPARENCY.additionalFeedback">
					<template #label>Tell us more about your rating.</template>

					<template #input>
						<textarea
							v-model.trim="v$['PERFORMANCE_CONTEXT_PERFORMANCE_TRANSPARENCY'].$model.additionalFeedback"
							class="input mt-100"
							placeholder="Start typing (optional)"
							maxlength="1000"
							cols="100"
							:rows="1"
						>
						</textarea>
					</template>
				</InputField>

				<hr class="divider mx-0 my-150" />

				<InputField class="mb-250" :field="v$.PERFORMANCE_CONTEXT_OPEN_RESPONSE.additionalFeedback">
					<template #label
						>Is there any additional feedback you would like to share on this feature?</template
					>
					<template #input>
						<textarea
							v-model.trim="v$['PERFORMANCE_CONTEXT_OPEN_RESPONSE'].$model.additionalFeedback"
							class="input mt-100"
							placeholder="Start typing (optional)"
							maxlength="1000"
							cols="100"
							:rows="1"
						>
						</textarea>
					</template>
				</InputField>
			</div>
		</template>

		<template #buttons>
			<SubmitButton
				data-test="micro-feedback-submit"
				class="button-default col-12-smo align-self-flex-end-md"
				:is-working="working"
				:v$="v$"
				@sb-click="submit()"
			>
				Submit feedback
			</SubmitButton>
		</template>
	</StandardModal>
</template>
<script setup lang="ts">
import { computed, reactive, ref } from 'vue';
import { MicroFeedbackReactionType, MicroFeedbackType } from 'types/micro-feedback';
import { getDistinctId } from '@utils/analytics';
import InputField from '@components/form/input-field.vue';
import MicroFeedbackRadios from '@components/micro-feedback/micro-feedback-radios.vue';
import { required } from '@vuelidate/validators';
import StandardModal from '@components/modals/standard-modal.vue';
import SubmitButton from '@components/form/submit-button.vue';
import { submitMicroFeedbackSatisfactionResponse } from '@api/micro-feedback';
import useVuelidate from '@vuelidate/core';

interface Emits {
	(e: 'modal-close'): void;
}

type EventType =
	| 'PERFORMANCE_CONTEXT_RATE_NEW_FEATURE'
	| 'PERFORMANCE_CONTEXT_EASE_OF_USE'
	| 'PERFORMANCE_CONTEXT_IMPACT_ON_RETURNS'
	| 'PERFORMANCE_CONTEXT_PERFORMANCE_TRANSPARENCY'
	| 'PERFORMANCE_CONTEXT_OPEN_RESPONSE';

type EventTypeState = {
	satisfactionResponse: MicroFeedbackReactionType | undefined;
	additionalFeedback: string;
};

type FormState = Record<EventType, EventTypeState>;

const rules = computed(() => ({
	PERFORMANCE_CONTEXT_RATE_NEW_FEATURE: { satisfactionResponse: { required }, additionalFeedback: {} },
	PERFORMANCE_CONTEXT_EASE_OF_USE: { satisfactionResponse: { required }, additionalFeedback: {} },
	PERFORMANCE_CONTEXT_IMPACT_ON_RETURNS: { satisfactionResponse: { required }, additionalFeedback: {} },
	PERFORMANCE_CONTEXT_PERFORMANCE_TRANSPARENCY: { satisfactionResponse: { required }, additionalFeedback: {} },
	PERFORMANCE_CONTEXT_OPEN_RESPONSE: { satisfactionResponse: {}, additionalFeedback: {} }
}));

const state = reactive<FormState>({
	PERFORMANCE_CONTEXT_RATE_NEW_FEATURE: { satisfactionResponse: undefined, additionalFeedback: '' },
	PERFORMANCE_CONTEXT_EASE_OF_USE: { satisfactionResponse: undefined, additionalFeedback: '' },
	PERFORMANCE_CONTEXT_IMPACT_ON_RETURNS: { satisfactionResponse: undefined, additionalFeedback: '' },
	PERFORMANCE_CONTEXT_PERFORMANCE_TRANSPARENCY: { satisfactionResponse: undefined, additionalFeedback: '' },
	PERFORMANCE_CONTEXT_OPEN_RESPONSE: { satisfactionResponse: undefined, additionalFeedback: '' }
});

const emit = defineEmits<Emits>();

const v$ = useVuelidate(rules, state);

const working = ref(false);

const distinctId = computed((): string => {
	return getDistinctId() ?? '';
});

function close(): void {
	emit('modal-close');
}

function reactionSelected(eventType: keyof FormState, value: MicroFeedbackReactionType): void {
	state[eventType].satisfactionResponse = value;
}

async function submit(): Promise<void> {
	working.value = true;

	try {
		const promises = Object.entries(state).map(([key, value]) =>
			submitMicroFeedbackSatisfactionResponse(
				{
					associatedEventId: '',
					distinctId: distinctId.value,
					microFeedbackEventType: key as MicroFeedbackType,
					satisfactionResponse: value.satisfactionResponse ?? 'NEUTRAL',
					additionalFeedback: value.additionalFeedback
				},
				false
			)
		);

		await Promise.all(promises);
	} catch {
	} finally {
		close();
	}
}
</script>

<style lang="scss" scoped>
@use '../../styles/constants/_colors.scss' as *;

.divider {
	border: none;
	border-top: 1px solid dark(10);
}

textarea {
	padding: 0.75rem;
	resize: vertical;
}
</style>
