<template>
	<li class="bt">
		<BaseLink
			classes="main-nav__sub-menu-item-link main-nav__sub-menu-item-link--notification display-flex align-items-center"
			:link="{ href: `${notification.linkUrl}?cta=Notification%20Dropdown` }"
			:click-text="'notification ' + notification.title"
		>
			<img
				class="rounded"
				style="width: 3rem; height: 3rem"
				:src="notification.thumbnailUrl"
				alt=""
				role="presentation"
			/>
			<div class="line-height-125 ml-100 flex-1">
				<p class="eta muted" style="color: #22262a">{{ notification.title }}</p>
				<p class="zeta mt-25 font-weight-bold heading-text-color">
					{{ notification.description }}
				</p>
				<time class="eta muted mt-25" style="color: #22262a">{{ notification.displayDateFormatted }}</time>
			</div>
		</BaseLink>
	</li>
</template>

<script setup lang="ts">
import { NotificationItem } from 'types/header';

interface Props {
	notification: NotificationItem;
}
defineProps<Props>();
</script>
