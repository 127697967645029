import { currency, number } from '@filters/shared-filters';
import AccountChecklistModule from '@components/account/modular-dashboard/modules/custom/account-checklist-module.vue';
import AccountValueChartModule from '@components/account/modular-dashboard/modules/custom/account-value-chart-module.vue';
import AlertModule from '@components/account/modular-dashboard/modules/alert-module.vue';
import DeltaModule from '@components/account/modular-dashboard/modules/delta-module.vue';
import DividendsModule from '@components/account/modular-dashboard/modules/custom/dividends-module.vue';
import FeaturedModule from '@components/account/modular-dashboard/modules/featured-module.vue';
import InvestorGoalsModule from '@components/account/modular-dashboard/modules/custom/investor-goals-module.vue';
import { ModularDashboardDeltaItemFormatType } from 'types/modular-dashboard/delta-module';
import { ModularDashboardModule } from 'types/modular-dashboard';
import NewsfeedModule from '@components/account/modular-dashboard/modules/custom/newsfeed-module.vue';
import PortfolioOverviewModule from '@components/account/modular-dashboard/modules/custom/portfolio-overview-module.vue';
import ProgressModule from '@components/account/modular-dashboard/modules/custom/progress-module.vue';
import StandardModule from '@components/account/modular-dashboard/modules/standard-module.vue';
import VerticalMediaCardModule from '@components/account/modular-dashboard/modules/vertical-media-card-module.vue';
import type { Component as ComponentType } from 'vue';

export function getModuleType(module: ModularDashboardModule): ComponentType | undefined {
	switch (module.type) {
		case 'DELTA':
			return DeltaModule;
		case 'ALERT':
			return AlertModule;
		case 'CARD':
			return VerticalMediaCardModule;
		case 'FEATURED_CONTENT':
			return FeaturedModule;
		case 'CUSTOM':
			switch (module.name) {
				case 'ACCOUNT_CHECKLIST':
					return AccountChecklistModule;
				case 'ACCOUNT_VALUE_CHART':
					return AccountValueChartModule;
				case 'DIVIDENDS':
					return DividendsModule;
				case 'INVESTOR_GOAL':
					return InvestorGoalsModule;
				case 'NEWSFEED':
					return NewsfeedModule;
				case 'PORTFOLIO_OVERVIEW':
					return PortfolioOverviewModule;
				case 'PROGRESS':
					return ProgressModule;
				default:
					return undefined;
			}
		case 'STANDARD':
			return StandardModule;
		default:
			return undefined;
	}
}

export function getFormattedDeltaValue(value: string, format: ModularDashboardDeltaItemFormatType): string {
	switch (format) {
		case 'PERCENTAGE':
			return `${value}%`;
		case 'CURRENCY':
			return currency(value);
		case 'DECIMAL':
			return number(value, 2);
		case 'INTEGER':
			return number(value);
		default:
			return value;
	}
}
