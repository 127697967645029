import { app } from '@store/modules/app';
import { datadogRum } from '@datadog/browser-rum';
import { FundriseRouteConfig } from 'vue-router';
import { logError } from '@utils/error-tracking';
import { login } from '@store/modules/login';
import { validateRouteAndNavigate } from './login-state-guards';

/* eslint-disable @typescript-eslint/explicit-function-return-type */
const loginRoutes: Array<FundriseRouteConfig> = [
	{
		path: `/login-verify`,
		name: 'login-two-factor',
		meta: { minTokenScopeRequired: 'REQUIRES_ADDITIONAL_AUTH' },
		beforeEnter: async (to, from, next) => {
			if (app.isAuthenticated) {
				next({ name: 'account-overview' });
			} else if (app.tokenScope === 'REQUIRES_ADDITIONAL_AUTH') {
				try {
					await login.storeTwoFactorMetadata();
					next();
				} catch {
					datadogRum.addAction('forbidden route', { from: from.name, to: to.name, scope: app.tokenScope });
					next({ name: 'forbidden' });
				}
			} else {
				datadogRum.addAction('forbidden route', { from: from.name, to: to.name, scope: app.tokenScope });
				next({ name: 'forbidden' });
			}
		},
		component: () => import('@views/account/login/login-two-factor.vue'),
		redirect: { name: 'login-two-factor-select' },
		children: [
			{
				path: `select`,
				name: 'login-two-factor-select',
				meta: { minTokenScopeRequired: 'REQUIRES_ADDITIONAL_AUTH' },
				beforeEnter: async (to, from, next) => {
					login.resetTwoFactorSelections();
					await validateRouteAndNavigate(next, 'login-two-factor-select');
				},
				component: () => import('@views/account/login/login-two-factor-select.vue')
			},
			{
				path: `confirm`,
				name: 'login-two-factor-confirm',
				meta: { minTokenScopeRequired: 'REQUIRES_ADDITIONAL_AUTH' },
				beforeEnter: async (to, from, next) => {
					await validateRouteAndNavigate(next, 'login-two-factor-confirm');
				},
				component: () => import('@views/account/login/login-two-factor-confirm.vue')
			}
		]
	},
	{
		path: `/login`,
		name: 'login',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED', suppressDefaultMxpViewEvent: true },
		beforeEnter: async (to, from, next) => {
			const isMaintenanceWindow = await app.isFeatureEnabled('app-down-maintenance-window');
			if (isMaintenanceWindow) {
				next({ name: 'maintenance' });
			} else {
				try {
					if (app.isAuthenticated || app.oauthToken || app.tokenScope !== 'UNAUTHENTICATED') {
						await app.revokeUserToken();
					}
				} catch {}

				next();
			}
		},
		component: () => import('@views/login/login-view.vue')
	},
	{
		path: `/logout-success`,
		name: 'logout-success',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		component: () => import('@views/logout/logout-success.vue'),
		beforeEnter: () => {
			try {
				if (app.isAuthenticated || app.oauthToken || app.tokenScope !== 'UNAUTHENTICATED') {
					app.revokeUserToken();
				}
			} catch {}
		}
	},
	{
		path: `/locked-out`,
		name: 'login-locked-out',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		component: () => import('@views/account/login/login-locked-out.vue')
	},
	{
		path: '/customer-service/substitute-user/:userId/make-the-switch',
		name: 'substitute-user',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		beforeEnter: async (to, from, next) => {
			if (app.isAuthenticated || app.oauthToken || app.tokenScope !== 'UNAUTHENTICATED') {
				await app.revokeUserToken();
			}

			try {
				await app.authenticateFromCornice();
				next({ name: 'home' });
			} catch (e) {
				if (e instanceof Error || typeof e === 'string') {
					logError(e);
				}
				datadogRum.addAction('forbidden route', { from: from.name, to: to.name, scope: app.tokenScope });
				next({ name: 'forbidden' });
			}
		},
		// revisit using redirect
		component: () => import('@views/login/login-view.vue')
	}
];
export default loginRoutes;
