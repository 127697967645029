<template>
	<div class="display-flex flex-direction-column justify-content-space-between gap-150">
		<div class="display-flex gap-150">
			<div class="performance-context-card__header body-md flex-1">{{ cardData.headline }}</div>
			<img
				v-if="cardData.thumbnailUrl"
				:src="cardData.thumbnailUrl"
				alt=""
				role="presentation"
				class="article-card-image"
				data-test="article-card-image"
			/>
		</div>
		<div class="body-xs text-color-dark-70">
			<div v-for="(footnote, idx) in cardData.footnotes" :key="idx">{{ footnote }}</div>
		</div>
	</div>
</template>
<script setup lang="ts">
import { PerformanceContextCardArticleEvent } from 'types/performance-context';

interface Props {
	cardData: PerformanceContextCardArticleEvent;
}
defineProps<Props>();
</script>

<style lang="scss" scoped>
.article-card-image {
	width: 80px;
	height: 80px;
	border-radius: 4px;
	object-fit: cover;
}
</style>
