import { apiRequest, investmentEntityRequest } from '@utils/request';
import ReitElement from '@classes/ReitElement';
import { WeightedReitElementApi } from 'types/reit-element';

export async function getReitElementsForEntity(): Promise<Array<ReitElement>> {
	const response = await investmentEntityRequest.get(`/portfolio/reit-elements`);
	return response.data.map(
		(weightedReitElement: WeightedReitElementApi) =>
			new ReitElement(weightedReitElement.reitElement, weightedReitElement.weightedAllocation)
	);
}

export async function getReitElementsForReit(reitId: string): Promise<Array<ReitElement>> {
	const response = await apiRequest.get(`/reits/${reitId}/reit-elements`);
	return response.data.map(
		(weightedReitElement: WeightedReitElementApi) =>
			new ReitElement(weightedReitElement.reitElement, weightedReitElement.weightedAllocation)
	);
}

export async function getReitElementsForPlan(planId: string): Promise<Array<ReitElement>> {
	const response = await apiRequest.get(`/ria/${planId}/reit-elements`);
	return response.data.map(
		(weightedReitElement: WeightedReitElementApi) =>
			new ReitElement(weightedReitElement.reitElement, weightedReitElement.weightedAllocation)
	);
}

export async function getReitElement(reitElementId: string): Promise<ReitElement> {
	const response = await apiRequest.get(`/reit-elements/${reitElementId}`);
	return response.data;
}
