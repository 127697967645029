import { app } from '@store/modules/app';
import { forgotPasswordErrorRoute } from '@utils/forgot-password-error-routing';
import { FundriseRouteConfig } from 'vue-router';
import { genericErrorToast } from '@constants';
import { resetPassword } from '@store/modules/reset-password';
import { v4 as uuidv4 } from 'uuid';

const resetPasswordRoutes: Array<FundriseRouteConfig> = [
	{
		path: `/forgotpassword`,
		name: 'forgot-password',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		beforeEnter: async (to, from, next) => {
			if (app.isAuthenticated) {
				next({ name: 'profile-password-edit' });
			}

			next();
		},
		component: () => import('@views/forgot-password/forgot-password.vue'),
		redirect: { name: 'forgot-password-form' },
		children: [
			{
				path: `form`,
				name: 'forgot-password-form',
				meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
				component: () => import('@views/forgot-password/forgot-password-form.vue')
			},
			{
				path: `email-sent`,
				name: 'forgot-password-email-sent',
				meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
				component: () => import('@views/forgot-password/forgot-password-email-sent.vue')
			},
			{
				path: `additional-questions`,
				name: 'forgot-password-additional-questions',
				meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
				beforeEnter: async (to, from, next) => {
					const passwordResetKey = sessionStorage.getItem('passwordResetKey');
					sessionStorage.removeItem('passwordResetKey');

					if (app.isAuthenticated) {
						next({ name: 'account-overview', replace: true });
					} else if (!passwordResetKey && !resetPassword.passwordResetKey) {
						next({ name: 'forgot-password-invalid-key', replace: true });
					} else if (!resetPassword.dataRetrieved && passwordResetKey) {
						const uuid = uuidv4();
						resetPassword.UPDATE_PASSWORD_RESET_SESSION_UUID(uuid);
						resetPassword.UPDATE_PASSWORD_RESET_KEY(passwordResetKey);
						try {
							await resetPassword.initiateResetPasswordFlow();
							switch (resetPassword.forgotPasswordMetaData?.authType) {
								case 'USER_ENTITY_DETAILS':
									next({
										name: 'forgot-password-additional-questions-entity-questions',
										replace: true
									});
									break;
								case 'USER_SECURITY_QUESTIONS':
									next({
										name: 'forgot-password-additional-questions-security-questions',
										replace: true
									});
									break;
								case 'VERIFIED_MOBILE_CODE_DELIVERY':
									next({
										name: 'forgot-password-additional-questions-two-factor-select',
										replace: true
									});
									break;
								case 'NO_AUTH_POSSIBLE':
									next({
										name: 'forgot-password-reset-password-form',
										replace: true
									});
									break;
								default:
									next({
										name: 'home',
										replace: true
									});
									break;
							}
							// eslint-disable-next-line @typescript-eslint/no-explicit-any
						} catch (error: any) {
							if (error.response?.data && error.response.status === 401) {
								const errorRoute = forgotPasswordErrorRoute(error.response.data.errorCode);
								next({ name: errorRoute, replace: true });
							} else {
								next({ name: 'home', replace: true });
								app.ADD_TOAST(genericErrorToast);
							}
						}
					} else {
						next();
					}
				},
				component: () => import('@views/forgot-password/forgot-password-additional-questions.vue'),
				redirect: { name: 'forgot-password-additional-questions-two-factor-select' },
				children: [
					{
						path: `two-factor-select`,
						name: 'forgot-password-additional-questions-two-factor-select',
						meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
						component: () => import('@views/forgot-password/forgot-password-two-factor-select.vue')
					},
					{
						path: `two-factor-confirm`,
						name: 'forgot-password-additional-questions-two-factor-confirm',
						meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
						component: () => import('@views/forgot-password/forgot-password-two-factor-confirm.vue')
					},
					{
						path: `security-questions`,
						name: 'forgot-password-additional-questions-security-questions',
						meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
						component: () => import('@views/forgot-password/forgot-password-security-questions.vue')
					},
					{
						path: `entity-questions`,
						name: 'forgot-password-additional-questions-entity-questions',
						meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
						component: () => import('@views/forgot-password/forgot-password-entity-questions.vue')
					}
				]
			},
			{
				path: `guess-username-attempts-reached`,
				name: 'forgot-password-guess-username-attempts',
				meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
				component: () => import('@views/forgot-password/forgot-password-guess-username-attempts.vue')
			},
			{
				path: `auth-question-attempts-reached`,
				name: 'forgot-password-auth-question-attempts',
				meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
				component: () => import('@views/forgot-password/forgot-password-auth-question-attempts.vue')
			},
			{
				path: `invalid-key`,
				name: 'forgot-password-invalid-key',
				meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
				component: () => import('@views/forgot-password/forgot-password-invalid-key.vue')
			},
			{
				path: `reset-password-form`,
				name: 'forgot-password-reset-password-form',
				meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
				beforeEnter: async (to, from, next) => {
					if (resetPassword.passwordResetKey === '') {
						next({ name: 'forgot-password-invalid-key', replace: true });
					} else {
						next();
					}
				},
				component: () => import('@views/forgot-password/forgot-password-reset-password-form.vue')
			},
			{
				path: `reset-password-success`,
				name: 'forgot-password-reset-password-success',
				meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
				component: () => import('@views/forgot-password/forgot-password-reset-password-success.vue')
			}
		]
	},
	{
		path: `/reset-password/start`,
		name: 'reset-password-start',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		redirect: { name: 'forgot-password-form' }
	},
	{
		path: `/forgotpassword/start`,
		name: 'forgot-password-start',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		redirect: { name: 'forgot-password-additional-questions' }
	}
];

export default resetPasswordRoutes;
