import { FundriseRouteConfig, RouteLocationNormalized } from 'vue-router';
import { handleRequiredValues, isContribution, isExisting, isRollover } from './ira-add-funds-state-guards';
import {
	invalidIraType,
	iraAddFundsDisabled,
	iraAddFundsDisabledForUser,
	iraNotReadyToInvest,
	iraOnboardingIncomplete,
	missingAccountNumber
} from '@constants';
import { IraAddFundsRoute, IraFundingType, IraRequiredValue } from 'types/ira';
import { app } from '@store/modules/app';
import { flattenQueryParam } from '@utils/request';
import { investmentEntity } from '@store/modules/investment-entity';
import { ira } from '@store/modules/ira';
import { redirect } from '@utils/client';
import { user } from '@store/modules/user';

const contributionRouteOrder: Array<IraAddFundsRoute> = [
	'ira-add-funds-funding',
	'ira-add-funds-amount',
	'ira-add-funds-plan',
	'ira-add-funds-drip',
	'ira-add-funds-max-contribution',
	'ira-add-funds-funding-source',
	'ira-add-funds-bank-account-new',
	'ira-add-funds-confirm',
	'ira-add-funds-agreements',
	'ira-add-funds-success'
];

const transferRouteOrder: Array<IraAddFundsRoute> = [
	'ira-add-funds-funding',
	'ira-add-funds-important-information',
	'ira-add-funds-transfer-type',
	'ira-add-funds-account-age',
	'ira-add-funds-account-not-eligible',
	'ira-add-funds-transfer-incompatible',
	'ira-add-funds-transfer-account-info',
	'ira-add-funds-transfer-amount',
	'ira-add-funds-plan',
	'ira-add-funds-drip',
	'ira-add-funds-confirm',
	'ira-add-funds-agreements',
	'ira-add-funds-success'
];
const existingRouteOrder: Array<IraAddFundsRoute> = [
	'ira-add-funds-funding',
	'ira-add-funds-transfer-amount',
	'ira-add-funds-plan',
	'ira-add-funds-drip',
	'ira-add-funds-confirm',
	'ira-add-funds-agreements',
	'ira-add-funds-success'
];
const rolloverRouteOrder: Array<IraAddFundsRoute> = [
	'ira-add-funds-funding',
	'ira-add-funds-important-information',
	'ira-add-funds-rollover-type',
	'ira-add-funds-rollover-source-account-type',
	'ira-add-funds-rollover-mixed-error',
	'ira-add-funds-transfer-incompatible',
	'ira-add-funds-rollover-method',
	'ira-add-funds-rollover-check-date',
	'ira-add-funds-rollover-check-not-eligible',
	'ira-add-funds-transfer-amount',
	'ira-add-funds-confirm',
	'ira-add-funds-agreements',
	'ira-add-funds-success'
];

const addFundsRouteOrder: Record<IraFundingType, Array<IraAddFundsRoute>> = {
	CONTRIBUTION: contributionRouteOrder,
	EXISTING: existingRouteOrder,
	TRANSFER: transferRouteOrder,
	ROLLOVER: rolloverRouteOrder
};

const getFlowStepIndex = (name: IraAddFundsRoute): number => {
	return addFundsRouteOrder[ira.fundingType].indexOf(name) + 1;
};

const stepConstructor = (route: RouteLocationNormalized): number => {
	return getFlowStepIndex(route.name as IraAddFundsRoute);
};

const missingPersonalInfoErrorMessage = (): string => {
	if (user.ssnNeedsUpdating && !user.hasAddress) {
		return 'Please confirm your SSN and address are properly set on your account.';
	}

	if (user.ssnNeedsUpdating) {
		return 'Please confirm your SSN is properly set on your account.';
	}

	if (!user.hasAddress) {
		return 'Please confirm your address is properly set on your account.';
	}

	return '';
};

/* eslint-disable @typescript-eslint/explicit-function-return-type */
const iraAddFundsRoutes: FundriseRouteConfig = {
	path: `/ira/add-funds`,
	name: 'ira-add-funds-start',
	meta: { minTokenScopeRequired: 'FULL_ACCESS' },
	redirect: { name: 'ira-add-funds-funding' },
	beforeEnter: async (to, from, next) => {
		ira.UPDATE_FLOW('ADD-FUNDS');

		await user.storeAddress();
		if (user.ssnNeedsUpdating || !user.hasAddress) {
			const individualEntity = user.user?.entityDetails?.investmentEntities.find(
				(entity) => entity.entityType === 'INDIVIDUAL'
			);
			const individualEntityId = individualEntity?.investmentEntityId ?? '';

			if (investmentEntity?.entityType !== 'INDIVIDUAL') {
				await user.updateSelectedEntityWithoutReload({
					investmentEntityId: individualEntityId,
					nextRoute: {
						route: {
							name: 'settings-account-information-redirect'
						},
						next
					}
				});
			} else {
				next({
					name: 'settings-account-information-redirect',
					params: {
						individualEntityId
					},
					replace: true
				});
			}

			app.ADD_TOAST({
				type: 'error',
				message: missingPersonalInfoErrorMessage()
			});
		} else if (!user.hasIraEntity) {
			next({ name: 'account-overview' });
			app.ADD_TOAST({
				type: 'error',
				message: 'No IRA is available for IRA add funds.'
			});
		} else if (investmentEntity.entityType !== 'IRA') {
			await user.updateSelectedEntityWithoutReload({
				investmentEntityId: user.iraEntities[0].investmentEntityId,
				nextRoute: { route: { name: 'ira-add-funds-start' }, next }
			});
		} else if (!ira.dataRetrieved) {
			try {
				const reitOfferingVintageId = flattenQueryParam(to.query.reitOfferingVintageId);
				await ira.getIraInfoForRepeatInvestment(reitOfferingVintageId);
				next({ name: 'ira-add-funds-funding', replace: true });
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				if (error.response?.data && error.response.status === 403) {
					switch (error.response.data.errorCode) {
						case missingAccountNumber:
							next({ name: 'ira-start', replace: true });
							break;
						case iraOnboardingIncomplete:
						case iraNotReadyToInvest:
							ira.UPDATE_DATA_RETRIEVED(true);
							next({ name: 'ira-add-funds-not-ready', replace: true });
							break;
						case invalidIraType:
							ira.UPDATE_DATA_RETRIEVED(true);
							next({ name: 'ira-add-funds-invalid-ira', replace: true });
							break;
						case iraAddFundsDisabled:
						case iraAddFundsDisabledForUser:
							ira.UPDATE_DATA_RETRIEVED(true);
							next({ name: 'ira-add-funds-disabled', replace: true });
							break;
						default:
							redirect('/account/overview');
							break;
					}
				} else if (error.message === 'EDIRECT_INELIGIBLE') {
					next({ name: 'account-overview', replace: true });
					app.ADD_TOAST({
						type: 'error',
						message: 'You are not eligible for direct investment in the selected offering at this time.'
					});
				} else {
					next({ name: 'account-overview', replace: true });
				}
			}
		} else {
			next();
		}
	},
	component: () => import('@views/ira/add-funds/ira-add-funds-start.vue'),
	children: [
		{
			path: `disabled`,
			name: 'ira-add-funds-disabled',
			meta: { step: stepConstructor, minTokenScopeRequired: 'FULL_ACCESS' },
			component: () => import('@views/ira/signup/ira-disabled.vue')
		},
		{
			path: `not-ready`,
			name: 'ira-add-funds-not-ready',
			meta: { step: stepConstructor, minTokenScopeRequired: 'FULL_ACCESS' },
			component: () => import('@views/ira/add-funds/ira-add-funds-not-ready.vue')
		},
		{
			path: `invalid-ira`,
			name: 'ira-add-funds-invalid-ira',
			meta: { step: stepConstructor, minTokenScopeRequired: 'FULL_ACCESS' },
			component: () => import('@views/ira/add-funds/ira-add-funds-invalid-ira.vue')
		},
		{
			path: `funding`,
			meta: { step: stepConstructor, minTokenScopeRequired: 'FULL_ACCESS' },
			name: 'ira-add-funds-funding',
			component: () => import('@views/ira/signup/ira-funding.vue')
		},
		{
			path: `information`,
			meta: { step: stepConstructor, minTokenScopeRequired: 'FULL_ACCESS' },
			name: 'ira-add-funds-important-information',
			beforeEnter: async (to, from, next) => {
				const requiredVals: Array<IraRequiredValue> = ['rolloverOrTransferFlow'];
				await handleRequiredValues(next, requiredVals);
			},
			component: () => import('@views/ira/signup/ira-important-info.vue')
		},
		{
			path: `account-type`,
			meta: { step: stepConstructor, minTokenScopeRequired: 'FULL_ACCESS' },
			name: 'ira-add-funds-transfer-type',
			beforeEnter: async (to, from, next) => {
				const requiredVals: Array<IraRequiredValue> = ['transferFlow'];
				await handleRequiredValues(next, requiredVals);
			},
			component: () => import('@views/ira/signup/ira-transfer-type.vue')
		},
		{
			path: `rollover-type`,
			name: 'ira-add-funds-rollover-type',
			meta: { step: stepConstructor, minTokenScopeRequired: 'FULL_ACCESS' },
			beforeEnter: async (to, from, next) => {
				const requiredVals: Array<IraRequiredValue> = ['rolloverFlow'];
				await handleRequiredValues(next, requiredVals);
			},
			component: () => import('@views/ira/signup/ira-rollover-type.vue')
		},
		{
			path: `rollover-account-type`,
			name: 'ira-add-funds-rollover-source-account-type',
			meta: { step: stepConstructor, minTokenScopeRequired: 'FULL_ACCESS' },
			beforeEnter: async (to, from, next) => {
				const requiredVals: Array<IraRequiredValue> = ['rolloverFlow', 'rolloverType'];
				await handleRequiredValues(next, requiredVals);
			},
			component: () => import('@views/ira/signup/ira-rollover-source-account-type.vue')
		},
		{
			path: `rollover-not-allowed`,
			name: 'ira-add-funds-rollover-mixed-error',
			meta: { step: stepConstructor, minTokenScopeRequired: 'FULL_ACCESS' },
			beforeEnter: async (to, from, next) => {
				const requiredVals: Array<IraRequiredValue> = ['rolloverFlow'];
				await handleRequiredValues(next, requiredVals);
			},
			component: () => import('@views/ira/signup/ira-rollover-mixed-error.vue')
		},
		{
			path: `rollover-method`,
			name: 'ira-add-funds-rollover-method',
			meta: { step: stepConstructor, minTokenScopeRequired: 'FULL_ACCESS' },
			beforeEnter: async (to, from, next) => {
				const requiredVals: Array<IraRequiredValue> = ['rolloverFlow', 'rolloverType', 'sourceAccountType'];
				await handleRequiredValues(next, requiredVals);
			},
			component: () => import('@views/ira/signup/ira-rollover-method.vue')
		},
		{
			path: `rollover-date`,
			name: 'ira-add-funds-rollover-check-date',
			meta: { step: stepConstructor, minTokenScopeRequired: 'FULL_ACCESS' },
			beforeEnter: async (to, from, next) => {
				const requiredVals: Array<IraRequiredValue> = [
					'rolloverFlow',
					'rolloverType',
					'sourceAccountType',
					'rolloverMethodIndirect'
				];
				await handleRequiredValues(next, requiredVals);
			},
			component: () => import('@views/ira/signup/ira-rollover-check-date.vue')
		},
		{
			path: `rollover-ineligible`,
			name: 'ira-add-funds-rollover-check-not-eligible',
			meta: { step: stepConstructor, minTokenScopeRequired: 'FULL_ACCESS' },
			beforeEnter: async (to, from, next) => {
				const requiredVals: Array<IraRequiredValue> = [
					'rolloverFlow',
					'rolloverType',
					'sourceAccountType',
					'rolloverMethodIndirect',
					'rolloverCheckDate'
				];
				await handleRequiredValues(next, requiredVals);
			},
			component: () => import('@views/ira/signup/ira-rollover-check-not-eligible.vue')
		},
		{
			path: `account-age`,
			meta: { step: stepConstructor, minTokenScopeRequired: 'FULL_ACCESS' },
			name: 'ira-add-funds-account-age',
			beforeEnter: async (to, from, next) => {
				const requiredVals: Array<IraRequiredValue> = [
					'transferFlow',
					'sourceAccountType',
					'simpleSourceAccountType'
				];
				await handleRequiredValues(next, requiredVals);
			},
			component: () => import('@views/ira/signup/ira-account-age.vue')
		},
		{
			path: `account-not-eligible`,
			meta: { step: stepConstructor, minTokenScopeRequired: 'FULL_ACCESS' },
			name: 'ira-add-funds-account-not-eligible',
			beforeEnter: async (to, from, next) => {
				const requiredVals: Array<IraRequiredValue> = [
					'transferFlow',
					'sourceAccountType',
					'simpleSourceAccountType',
					'simpleIraFirstContributionDate',
					'simpleIraFirstContributionDateValidFalse'
				];
				await handleRequiredValues(next, requiredVals);
			},
			component: () => import('@views/ira/signup/ira-account-not-eligible.vue')
		},
		{
			path: `incompatible`,
			name: 'ira-add-funds-transfer-incompatible',
			meta: { step: stepConstructor, minTokenScopeRequired: 'FULL_ACCESS' },
			beforeEnter: async (to, from, next) => {
				const requiredVals: Array<IraRequiredValue> = ['rolloverOrTransferFlow'];
				await handleRequiredValues(next, requiredVals);
			},
			component: () => import('@views/ira/add-funds/ira-add-funds-transfer-incompatible.vue')
		},
		{
			path: `previous-account`,
			name: 'ira-add-funds-transfer-account-info',
			meta: { step: stepConstructor, minTokenScopeRequired: 'FULL_ACCESS' },
			beforeEnter: async (to, from, next) => {
				const requiredVals: Array<IraRequiredValue> = ['transferFlow', 'sourceAccountType'];
				await handleRequiredValues(next, requiredVals);
			},
			component: () => import('@views/ira/signup/ira-transfer-account-info.vue')
		},
		{
			path: `transfer-amount`,
			name: 'ira-add-funds-transfer-amount',
			meta: { step: stepConstructor, minTokenScopeRequired: 'FULL_ACCESS' },
			beforeEnter: async (to, from, next) => {
				let requiredVals: Array<IraRequiredValue> = [];
				if (isRollover()) {
					requiredVals = ['rolloverFlow', 'sourceAccountType', 'rolloverMethod'];
				} else if (isExisting()) {
					requiredVals = ['transferOrExisting'];
				} else {
					requiredVals = [
						'transferFlow',
						'sourceAccountType',
						'sourceCustodianDtcInfo',
						'sourceAccountNumber'
					];
				}
				await handleRequiredValues(next, requiredVals);
			},
			component: () => import('@views/ira/signup/ira-transfer-amount.vue')
		},
		{
			path: `amount`,
			name: 'ira-add-funds-amount',
			meta: { step: stepConstructor, minTokenScopeRequired: 'FULL_ACCESS' },
			beforeEnter: async (to, from, next) => {
				const requiredVals: Array<IraRequiredValue> = ['contributionFlow'];
				await handleRequiredValues(next, requiredVals);
			},
			component: () => import('@views/ira/signup/ira-amount.vue')
		},
		{
			path: `plan`,
			name: 'ira-add-funds-plan',
			meta: { step: stepConstructor, minTokenScopeRequired: 'FULL_ACCESS' },
			beforeEnter: async (to, from, next) => {
				const requiredVals: Array<IraRequiredValue> = ['addFundsPlanSelectionRequired'];
				await handleRequiredValues(next, requiredVals);
			},
			component: () => import('@views/ira/signup/ira-plan.vue')
		},
		{
			path: `max-contribution`,
			name: 'ira-add-funds-max-contribution',
			meta: { step: stepConstructor, minTokenScopeRequired: 'FULL_ACCESS' },
			beforeEnter: async (to, from, next) => {
				if (from && from.name === 'ira-add-funds-amount') {
					next();
				} else {
					next({ name: 'ira-add-funds-amount' });
				}
			},
			component: () => import('@views/ira/signup/ira-max-contribution.vue')
		},
		{
			path: `funding-source`,
			name: 'ira-add-funds-funding-source',
			meta: { step: stepConstructor, minTokenScopeRequired: 'FULL_ACCESS' },
			beforeEnter: async (to, from, next) => {
				const requiredVals: Array<IraRequiredValue> = ['contributionFlow', 'selectedAmount'];
				await handleRequiredValues(next, requiredVals);
			},
			component: () => import('@views/ira/signup/ira-funding-source.vue')
		},
		{
			path: `bank-account-new`,
			name: 'ira-add-funds-bank-account-new',
			meta: { step: stepConstructor, minTokenScopeRequired: 'FULL_ACCESS' },
			beforeEnter: async (to, from, next) => {
				if (from && from.name === 'ira-add-funds-funding-source') {
					next();
				} else {
					next({ name: 'ira-add-funds-funding-source' });
				}
			},
			component: () => import('@views/ira/signup/ira-bank-account-new.vue')
		},
		{
			path: `drip`,
			name: 'ira-add-funds-drip',
			meta: { step: stepConstructor, minTokenScopeRequired: 'FULL_ACCESS' },
			beforeEnter: async (to, from, next) => {
				const requiredVals: Array<IraRequiredValue> = ['addFundsdripPreferenceRequired'];
				await handleRequiredValues(next, requiredVals);
			},
			component: () => import('@views/ira/signup/ira-drip.vue')
		},
		{
			path: `confirmation`,
			name: 'ira-add-funds-confirm',
			meta: { step: stepConstructor, minTokenScopeRequired: 'FULL_ACCESS' },
			beforeEnter: async (to, from, next) => {
				let requiredVals: Array<IraRequiredValue> = [];
				if (isContribution()) {
					requiredVals = [
						'selectedAmount',
						'selectedTaxYear',
						'plan',
						'dripPreference',
						'selectedPaymentMethod'
					];
				} else if (isExisting()) {
					requiredVals = ['selectedAmount', 'plan', 'dripPreference'];
				} else if (isRollover()) {
					requiredVals = ['sourceAccountType', 'rolloverMethod', 'selectedAmount'];
				} else {
					requiredVals = [
						'sourceAccountType',
						'sourceCustodianDtcInfo',
						'sourceAccountNumber',
						'selectedAmount',
						'plan',
						'dripPreference'
					];
				}
				await handleRequiredValues(next, requiredVals);
			},
			component: () => import('@views/ira/signup/ira-confirm.vue')
		},
		{
			path: `agreements`,
			name: 'ira-add-funds-agreements',
			meta: { step: stepConstructor, minTokenScopeRequired: 'FULL_ACCESS' },
			beforeEnter: async (to, from, next) => {
				let requiredVals: Array<IraRequiredValue> = [];
				if (isRollover()) {
					requiredVals = ['rolloverLegacy'];
				} else {
					requiredVals = ['reitInvestmentOrderGroupId'];
				}
				await handleRequiredValues(next, requiredVals);
			},
			component: () => import('@views/ira/signup/ira-agreements.vue')
		},
		{
			path: `success`,
			name: 'ira-add-funds-success',
			meta: { step: stepConstructor, minTokenScopeRequired: 'FULL_ACCESS' },
			beforeEnter: async (to, from, next) => {
				let requiredVals: Array<IraRequiredValue> = [];
				if (isRollover()) {
					requiredVals = ['contactSet'];
				} else {
					requiredVals = ['reitInvestmentOrderGroupId', 'orderGroupAcknowledged'];
				}
				await handleRequiredValues(next, requiredVals);
			},
			component: () => import('@views/ira/add-funds/ira-add-funds-success.vue')
		}
	]
};

export default iraAddFundsRoutes;
