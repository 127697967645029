<template>
	<div ref="target">
		<BaseAlert :alert="alertConfig" />
	</div>
</template>

<script lang="ts">
export default {
	name: 'AlertModule'
};
</script>

<script setup lang="ts">
import { BaseAlertComponentType, BaseAlertType, Link } from 'types/layout';
import { computed, onMounted, ref } from 'vue';
import { getLinkFromRoute } from '@utils/request';
import { ModularDashboardAlertModule } from 'types/modular-dashboard/alert-module';
import { trackMixpanelScrollIntoView } from '@utils/analytics';
import { useRouter } from 'vue-router';

interface Props {
	module: ModularDashboardAlertModule;
}

const props = defineProps<Props>();

const router = useRouter();

const target = ref<HTMLElement | null>(null);

const formattedAlertType = computed((): BaseAlertType => {
	return props.module.alertType.toLowerCase() as BaseAlertType;
});

const formattedCta = computed((): Link | undefined => {
	if (props.module.cta) {
		return {
			...getLinkFromRoute(router, props.module.cta.targetUrl ?? '', props.module.cta.text),
			target: props.module.cta.newTab ? '_blank' : '_self'
		};
	} else {
		return undefined;
	}
});

const alertConfig = computed((): BaseAlertComponentType => {
	return {
		type: formattedAlertType.value,
		heading: props.module.title,
		message: [props.module.subText ?? ''],
		cta: formattedCta.value
	};
});

onMounted(() => {
	if (target.value) {
		trackMixpanelScrollIntoView(target.value, {
			'View Title': props.module.title,
			'View Content': props.module.name,
			'View ID': props.module.alertType
		});
	}
});
</script>
