import { email } from '@store/modules/email';
import { EmailGroupEnum } from 'types/email';
import { FundriseRouteConfig } from 'vue-router';

const emailUnsubscribeRoutes: Array<FundriseRouteConfig> = [
	{
		path: '/email/inviteEmailUnsubscribe',
		name: 'invite-email-unsubscribe',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		redirect: (route) => ({
			name: 'email-unsubscribe',
			query: { ...route.query, invitation: 'true' },
			replace: true
		})
	},
	{
		path: '/email/iterable-unsubscribe/:unsubscribeKey/message-type/:messageTypeId',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		redirect: (route) => ({
			name: 'email-unsubscribe',
			query: { ...route.params },
			replace: true
		})
	},
	{
		path: '/email/nonUserUnsubscribe',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		name: 'non-user-unsubscribe',
		redirect: { name: 'email-unsubscribe' }
	},
	{
		path: '/email/unsubscribe',
		name: 'email-unsubscribe',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		component: () => import('@views/email/one-click-unsubscribe.vue'),
		beforeEnter: async (to, _from, next) => {
			if (email.unsubscribeKey) next();

			try {
				const {
					path,
					query: { unsubscribeKey, messageTypeId, emailGroup, invitation }
				} = to;
				if (invitation) {
					await email.oneClickUnsubscribeInvitation(unsubscribeKey as string);

					next();
				} else {
					await email.getEmailGroupSubscriptions(unsubscribeKey as string);

					if (messageTypeId) {
						await email.oneClickUnsubscribeIterable(messageTypeId as string);
					}
					if (emailGroup) {
						await email.oneClickUnsubscribeEmailGroup(emailGroup as EmailGroupEnum);
					}

					next({ path, query: { unsubscribeKey }, replace: true });
				}
			} catch (error) {
				next('404');
			}
		}
	}
];

export default emailUnsubscribeRoutes;
