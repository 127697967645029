import { app } from '@store/modules/app';
import { FundriseRouteConfig } from 'vue-router';
import { genericErrorToast } from '@constants';
import { includesRequiredQueryParams } from '@utils/request';

const oauthAuthorizationRoutes: Array<FundriseRouteConfig> = [
	{
		path: `/oauth/authorize`,
		name: 'oauth-authorize',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		beforeEnter: (to, from, next) => {
			const hasRequiredParams = includesRequiredQueryParams(['client_id', 'redirect_uri', 'response_type'], to);
			if (hasRequiredParams) {
				next();
			} else {
				app.ADD_TOAST(genericErrorToast);
				next({ name: 'account-overview' });
			}
		},
		component: () => import('@views/oauth-authorization/oauth-authorization.vue')
	}
];
export default oauthAuthorizationRoutes;
