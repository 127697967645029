const appCloudfrontPath = `${import.meta.env.VITE_CLOUDFRONT_DOMAIN}`;
const appBasePath = `${import.meta.env.VITE_BASE_PATH}`;

import {
	accountFundObjective,
	entityTypes,
	jointAccountTypes,
	llcTaxClassification,
	singleMemberLLCTaxClassification,
	tinTypeList,
	trustClassificationList,
	trustSubtypeList,
	trustTypeList
} from './account-info';
import { addFundsRouteOrder, minInvestmentAmount } from './add-funds';
import {
	autoInvestAmounts,
	autoInvestFrequency,
	autoInvestMonthlyDays,
	autoInvestSemiMonthlyDays
} from './auto-invest';

import encryptionMessage from './encryption-message';

import {
	backToDashboardLink,
	contactOurTeamLink,
	contactSupportLink,
	investmentsEmailLink,
	performanceDisclosure,
	redemptionLearnMoreLink,
	settingsInvestmentPlanViewLink,
	supportEmailLink,
	transactionsLink
} from './links';
import {
	forgotPasswordInvalidPasswordResetKey,
	forgotPasswordTooManyAuthAnswerAttempts,
	forgotPasswordTooManyUsernameGuesses,
	forgotPasswordUserIsMalicious,
	invalidIraType,
	iraAddFundsDisabled,
	iraAddFundsDisabledForUser,
	iraNotReadyToInvest,
	iraOnboardingIncomplete,
	iraOwnerNotUsResident,
	missingAccountNumber
} from './error-codes';

import {
	nameRegex,
	passwordLetterRegex,
	passwordMaxLength,
	passwordMinLength,
	passwordNumberRegex,
	passwordRegex
} from './validation';

import { ofundStrategy } from './ofund-strategy';

import { primaryDefaultChartColor, secondaryDefaultChartColor } from './highcharts-vue';

import { realEstateStrategies, realEstateStrategyColors, realEstateStrategySlugs } from './real-estate-strategies';

import { stateMap, states } from './states';

import { accreditationVerificationTypes } from './accreditation-verification-types';

import { ageLimits } from './age-limits';

import { annualReturnsDisclosureModalProps } from './annual-returns-data';

import { emailSubscriptionDetail } from './email';

import { genericErrorToast } from './toasts';

import { historicalReturnsData } from './historical-returns-data';

import {
	canvaCompanyId,
	disclaimerModalContent,
	flagshipFundOfferingId,
	fundriseEfundReitId,
	growthTechReitId,
	incomeFundOfferingId,
	intervalFundReitIds,
	ipoReitId,
	mergedEfundReitIds,
	ocfReitId,
	ofundReitId,
	vantaCompanyId
} from './reit';

import { makettoDocumentRedirects } from './document-redirects';

import { midYearReviewId } from './investor-update';

import { microFeedbackData } from './micro-feedback';

import { plansInfo } from './plans-info';

import { riaUpgradeRouteOrder } from './ria-upgrade';

import { ipoOverview, ipoTopQuestions } from './offerings';

import { timezonesInEU } from './timezones';

import { plaidEntry, wireEntry } from './payment';

import { hdyhOtherField } from './hdyh-survey';

import { annualRecurringThreshold, yearlyOneTimeThreshold } from './ira';

import { minNumberOfDividendDataPointsToShow, minNumberOfGrowthDataPointsToShow } from './offering-details';

import { advisorAuthorizationFormLink, advisorDashboardLink } from './advisor';

import { allowedMxpQueryParams } from './mixpanel';

import { storageKeyLoginRedirect } from './storage-keys';

import { moduleLoadingErrorMessage } from './modular-dashboard';

export {
	accountFundObjective,
	accreditationVerificationTypes,
	addFundsRouteOrder,
	advisorAuthorizationFormLink,
	advisorDashboardLink,
	ageLimits,
	allowedMxpQueryParams,
	annualRecurringThreshold,
	annualReturnsDisclosureModalProps,
	appBasePath,
	appCloudfrontPath,
	autoInvestAmounts,
	autoInvestFrequency,
	autoInvestMonthlyDays,
	autoInvestSemiMonthlyDays,
	backToDashboardLink,
	canvaCompanyId,
	contactOurTeamLink,
	contactSupportLink,
	disclaimerModalContent,
	emailSubscriptionDetail,
	encryptionMessage,
	entityTypes,
	flagshipFundOfferingId,
	forgotPasswordInvalidPasswordResetKey,
	forgotPasswordTooManyAuthAnswerAttempts,
	forgotPasswordTooManyUsernameGuesses,
	forgotPasswordUserIsMalicious,
	fundriseEfundReitId,
	genericErrorToast,
	growthTechReitId,
	hdyhOtherField,
	historicalReturnsData,
	incomeFundOfferingId,
	intervalFundReitIds,
	invalidIraType,
	investmentsEmailLink,
	ipoOverview,
	ipoReitId,
	ipoTopQuestions,
	iraAddFundsDisabled,
	iraAddFundsDisabledForUser,
	iraNotReadyToInvest,
	iraOnboardingIncomplete,
	iraOwnerNotUsResident,
	jointAccountTypes,
	llcTaxClassification,
	makettoDocumentRedirects,
	mergedEfundReitIds,
	microFeedbackData,
	midYearReviewId,
	minInvestmentAmount,
	minNumberOfDividendDataPointsToShow,
	minNumberOfGrowthDataPointsToShow,
	missingAccountNumber,
	moduleLoadingErrorMessage,
	nameRegex,
	ocfReitId,
	ofundReitId,
	ofundStrategy,
	passwordLetterRegex,
	passwordMaxLength,
	passwordMinLength,
	passwordNumberRegex,
	passwordRegex,
	performanceDisclosure,
	plaidEntry,
	plansInfo,
	primaryDefaultChartColor,
	realEstateStrategies,
	realEstateStrategyColors,
	realEstateStrategySlugs,
	redemptionLearnMoreLink,
	riaUpgradeRouteOrder,
	secondaryDefaultChartColor,
	settingsInvestmentPlanViewLink,
	singleMemberLLCTaxClassification,
	stateMap,
	states,
	storageKeyLoginRedirect,
	supportEmailLink,
	timezonesInEU,
	tinTypeList,
	transactionsLink,
	trustClassificationList,
	trustSubtypeList,
	trustTypeList,
	vantaCompanyId,
	wireEntry,
	yearlyOneTimeThreshold
};
